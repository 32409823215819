/**
 * @description Type client
 */
export enum ECustomerType {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
  EMPLOYEE = 'EMPLOYEE',
}

/**
 * @description Status client
 */
export enum ECustomerStatus {
  SUBMITTED = 'SUBMITTED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  CLOSED = 'CLOSED',
  LIMITED = 'LIMITED',
  QUEUED = 'QUEUED',
}
/**
 * @description KYC Status client
 */
export enum EKycStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

/**
 * @description email type
 */
export enum EEmailType {
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  PRIMARY = 'PRIMARY',
  ADDITIONAL = 'ADDITIONAL',
  BUSINESS = 'BUSINESS',
  NONE = 'NONE',
}

/**
 * @description CustomerDocumentStatus
 */
export enum EDocumentStatus {
  SUBMITTED = 'SUBMITTED',
  VALIDATED = 'VALIDATED',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  DELETED = 'DELETED',
  LOST = 'LOST',
  REJECTED = 'REJECTED',
  QUEUED = 'QUEUED',
  EXPIRED = 'EXPIRED',
  PROCESSING = 'PROCESSING',
  UNDER_INVESTIGATE = 'UNDER_INVESTIGATE',
  NEED_RELATION_DOCUMENT = 'NEED_RELATION_DOCUMENT',
}

/**
 * @description CustomerDocumentConfirmationType
 */
export enum EDocumentConfirmType {
  IDENTITY = 'IDENTITY',
  ADDRESS = 'ADDRESS',
  PHONE = 'PHONE',
  EFT_AUTHORIZATION = 'EFT_AUTHORIZATION',
  OTHER = 'OTHER',
  CARD = 'CARD',
  CHILD = 'CHILD',
  BUSINESS_IDENTITY = 'BUSINESS_IDENTITY',
  SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
  APPLICATION = 'APPLICATION',
  DEVICE = 'DEVICE',
  USERNAME = 'USERNAME',
  IDENTITY_VIDEO = 'IDENTITY_VIDEO',
}

export enum ECustomerPhoneType {
  HOME = 'HOME',
  WORK = 'WORK',
  MOBILE = 'MOBILE',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  NONE = 'NONE',
}

export enum ECustomerPhoneStatus {
  PRIMARY = 'PRIMARY',
  ADDITIONAL = 'ADDITIONAL',
  NONE = 'NONE',
}
export enum ECustomerAddressStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}
export enum ECustomerAddressType {
  UNSPECIFIED = 'UNSPECIFIED',
  HOME = 'HOME',
  BUSINESS = 'BUSINESS',
  HOME_AND_BUSINESS = 'HOME_AND_BUSINESS',
  RESIDENCE = 'RESIDENCE',
  OFFICE = 'OFFICE',
}

/**
 * @description CustomerDocumentType
 */
export enum EDocumentType {
  PHONE_BILL = 'PHONE_BILL',
  ELECTRICITY_BILL = 'ELECTRICITY_BILL',
  GAS_BILL = 'GAS_BILL',
  PASSPORT = 'PASSPORT',
  ID = 'ID',
  DRIVERS_LICENCE = 'DRIVERS_LICENCE',
  BANK_STATEMENT = 'BANK_STATEMENT',
  CARD_STATEMENT = 'CARD_STATEMENT',
  OTHER = 'OTHER',
  MATRICULA_CONSULAR = 'MATRICULA_CONSULAR',
  IFE_VOTER_CARD = 'IFE_VOTER_CARD',
  MILITARY_ID = 'MILITARY_ID',
  STATE_ID = 'STATE_ID',
  CORPORATION_REGISTRATION = 'CORPORATION_REGISTRATION',
  EFT_AUTHORIZATION_FORM = 'EFT_AUTHORIZATION_FORM',
  NOTARIZED_PASSPORT_TRANSLATION = 'NOTARIZED_PASSPORT_TRANSLATION',
  USER_PHOTO_HOLDING_ID = 'USER_PHOTO_HOLDING_ID',
  CARD_FRONT = 'CARD_FRONT',
  CARD_BACK = 'CARD_BACK',
  CARD_AUTHORIZATION_FORM = 'CARD_AUTHORIZATION_FORM',
  EMPOWERMENT = 'EMPOWERMENT',
  ARMED_FORCES_IDENTITY_CARD = 'ARMED_FORCES_IDENTITY_CARD',
  ENROLLMENT_ID = 'ENROLLMENT_ID',
  CIVIC_ID = 'CIVIC_ID',
  NATIONAL_FOREIGNERS_ID = 'NATIONAL_FOREIGNERS_ID',
  CEDULA_DE_IDENTIDAD = 'CEDULA_DE_IDENTIDAD',
  INDIVIDUAL_ENTRY_CARD = 'INDIVIDUAL_ENTRY_CARD',
  FIREARMS_LICENSE = 'FIREARMS_LICENSE',
  HEALTH_CARD = 'HEALTH_CARD',
  POPULATION_REGISTRATION_CARD = 'POPULATION_REGISTRATION_CARD',
  BARBADOS_VISITORS_REGISTRATION_CERTIFICATE = 'BARBADOS_VISITORS_REGISTRATION_CERTIFICATE',
  AGE_OF_MAJORITY_CARD = 'AGE_OF_MAJORITY_CARD',
  CANADIAN_CITIZENSHIP_CARD = 'CANADIAN_CITIZENSHIP_CARD',
  PERMANENT_RESIDENCE_CARD = 'PERMANENT_RESIDENCE_CARD',
  CANADIAN_FORCES_IDENTIFICATION_CARD = 'CANADIAN_FORCES_IDENTIFICATION_CARD',
  CEDULA_DE_CIUDADANIA = 'CEDULA_DE_CIUDADANIA',
  TARJETA_DE_IDENTIDAD = 'TARJETA_DE_IDENTIDAD',
  DANISH_SOCIAL_INSURANCE_CARD = 'DANISH_SOCIAL_INSURANCE_CARD',
  SII_CARD = 'SII_CARD',
  FRENCH_RESIDENCE_PERMIT = 'FRENCH_RESIDENCE_PERMIT',
  PERMIT_OF_RESIDENCE = 'PERMIT_OF_RESIDENCE',
  PERMANENT_IDENTITY_CARD = 'PERMANENT_IDENTITY_CARD',
  RESIDENT_CARD = 'RESIDENT_CARD',
  JAPANESE_HEALTH_CARD = 'JAPANESE_HEALTH_CARD',
  CERTIFICATE_OF_TRAVEL = 'CERTIFICATE_OF_TRAVEL',
  PASSPORT_FOR_FOREIGNERS = 'PASSPORT_FOR_FOREIGNERS',
  EUROPEAN_RESIDENCE_PERMIT = 'EUROPEAN_RESIDENCE_PERMIT',
  IDENTITY_CARD_FOR_FOREIGNERS = 'IDENTITY_CARD_FOR_FOREIGNERS',
  MONACO_RESIDENT_CARD = 'MONACO_RESIDENT_CARD',
  NORWEGIAN_BANK_IDENTITY_CARD = 'NORWEGIAN_BANK_IDENTITY_CARD',
  NORWEGIAN_POST_OFFICE_CARD = 'NORWEGIAN_POST_OFFICE_CARD',
  NIE_CARD = 'NIE_CARD',
  RESIDENCY_PERMIT = 'RESIDENCY_PERMIT',
  LOCAL_PASSPORT = 'LOCAL_PASSPORT',
  PASSPORT_CARD = 'PASSPORT_CARD',
  SOCIAL_SECURITY_CARD = 'SOCIAL_SECURITY_CARD',
  CHILD_ID = 'CHILD_ID',
  CHILD_BIRTH_CERTIFICATE = 'CHILD_BIRTH_CERTIFICATE',
  TRANSLATE = 'TRANSLATE',
  ARTICLES_OF_INCORPORATION = 'ARTICLES_OF_INCORPORATION',
  TEMPORARY_ID = 'TEMPORARY_ID',
  PUBLIC_AUTHORITY_BILL = 'PUBLIC_AUTHORITY_BILL',
  CABLE_BILL = 'CABLE_BILL',
  INTERNET_BILL = 'INTERNET_BILL',
  WATER_BILL = 'WATER_BILL',
  LEASE_CONTRACT = 'LEASE_CONTRACT',
  INSURANCE_BILL = 'INSURANCE_BILL',
  GARBAGE_BILL = 'GARBAGE_BILL',
  NOTARIZED_STATEMENT = 'NOTARIZED_STATEMENT',
  SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
  ACCOUNT_APPLICATION = 'ACCOUNT_APPLICATION',
  MERCHANT_APPLICATION = 'MERCHANT_APPLICATION',
  CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
  GOOD_STANDING = 'GOOD_STANDING',
  CERTIFICATE_OF_DIRECTORS = 'CERTIFICATE_OF_DIRECTORS',
  CERTIFICATE_OF_SHAREHOLDERS = 'CERTIFICATE_OF_SHAREHOLDERS',
  MEMORANDUM = 'MEMORANDUM',
  OFFICE_ADDRESS = 'OFFICE_ADDRESS',
  NEW_ACCOUNT_RESOLUTION = 'NEW_ACCOUNT_RESOLUTION',
  FINANCIAL_RECORDS = 'FINANCIAL_RECORDS',
  REFERENCE_LETTER = 'REFERENCE_LETTER',
  TAX_RETURN = 'TAX_RETURN',
  MARRIAGE_CERTIFICATE = 'MARRIAGE_CERTIFICATE',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  OFFICIAL_NAME_CHANGE_CERTIFICATE = 'OFFICIAL_NAME_CHANGE_CERTIFICATE',
  RENT_AGREEMENT = 'RENT_AGREEMENT',
  PASSPORT_REGISTRATION_STAMP = 'PASSPORT_REGISTRATION_STAMP',
  UTILITY_BILL = 'UTILITY_BILL',
  ID_BACK = 'ID_BACK',
  DRIVER_LICENSE_BACK = 'DRIVER_LICENSE_BACK',
  REGISTERED_OFFICE_CERTIFICATE = 'REGISTERED_OFFICE_CERTIFICATE',
  TRUST_DECLARATION = 'TRUST_DECLARATION',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  ANOTHER_ARRANGEMENT = 'ANOTHER_ARRANGEMENT',
  ANTI_MONEY_LAUNDERING_POLICY = 'ANTI_MONEY_LAUNDERING_POLICY',
  DATE_SECURITY_POLICY = 'DATE_SECURITY_POLICY',
  ANTI_CORRUPTION_POLICY = 'ANTI_CORRUPTION_POLICY',
  FINANCIAL_INSTITUTION_LICENSE = 'FINANCIAL_INSTITUTION_LICENSE',
  COMPLETED_WOLFSBERG_QUESTIONNAIRE = 'COMPLETED_WOLFSBERG_QUESTIONNAIRE',
  CAM_MODEL_IDENTIFICATION_POLICY = 'CAM_MODEL_IDENTIFICATION_POLICY',
  ONGOING_MONITORING_PROCEDURE = 'ONGOING_MONITORING_PROCEDURE',
  PROHIBITED_ESCORT_STATEMENT = 'PROHIBITED_ESCORT_STATEMENT',
  STREAMING_PLATFORM_DECLARATION = 'STREAMING_PLATFORM_DECLARATION',
  STUDIO_DECLARATION = 'STUDIO_DECLARATION',
  SAMPLE_CONTRACT_WITH_WEB_CAM_MODEL = 'SAMPLE_CONTRACT_WITH_WEB_CAM_MODEL',
  VIDEO_WEBCAM = 'VIDEO_WEBCAM',
}

export enum EBusinessRepresentativeStatus {
  SUBMITTED = 'SUBMITTED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}
export enum EBusinessRepresentativeType {
  CEO = 'CEO',
  DIRECTOR = 'DIRECTOR',
  OFFICER = 'OFFICER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  AUTHORIZED = 'AUTHORIZED',
  OWNER = 'OWNER',
  ULTIMATE_BENEFICIARY = 'ULTIMATE_BENEFICIARY',
  OWNER_COMPANY = 'OWNER_COMPANY',
  USER = 'USER',
  OTHER = 'OTHER',
}
export enum EAccessRights {
  NO_ACCESS = 'NO_ACCESS',
  READ = 'READ',
  FULL_ACCESS = 'FULL_ACCESS',
  INITIATE = 'INITIATE',
  CONFIRMED = 'CONFIRMED',
  UPLOADING = 'UPLOADING',
}

export enum EAdultLevel {
  NON_ADULT = 'NON_ADULT',
  INDIRECT_ADULT = 'INDIRECT_ADULT',
  ADULT = 'ADULT',
}
export enum ECustomerActionType {
  MISSING_EXPIRED_DOCUMENT = 'MISSING_EXPIRED_DOCUMENT',
  UNPAID_INVOICE = 'UNPAID_INVOICE',
  POSSIBLE_SANCTIONS_MATCH = 'POSSIBLE_SANCTIONS_MATCH',
  POSSIBLE_ADVERSE_MEDIA_MATCH = 'POSSIBLE_ADVERSE_MEDIA_MATCH',
  CURRENT_ACCOUNT_INVESTIGATION = 'CURRENT_ACCOUNT_INVESTIGATION',
}
export enum EDocumentCategory {
  IDENTITY = 'IDENTITY',
  ADDRESS = 'ADDRESS',
  PHONE = 'PHONE',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
  RELATION = 'RELATION',
  BUSINESS_ADDRESS = 'BUSINESS_ADDRESS',
  BUSINESS_REGISTERED_ADDRESS = 'BUSINESS_REGISTERED_ADDRESS',
  CONTROL_DOCUMENT = 'CONTROL_DOCUMENT',
  BUSINESS_ADDITIONAL_DOCUMENT = 'BUSINESS_ADDITIONAL_DOCUMENT',
}
export enum ETransferDirection {
  IN = 'IN',
  OUT = 'OUT',
  IN_AND_OUT = 'IN_AND_OUT',
  ANY = 'ANY',
}
export enum EBlacklistType {
  IP = 'IP',
  EMAIL = 'EMAIL',
  LAST_NAME = 'LAST_NAME',
  FIRST_NAME = 'FIRST_NAME',
  CARD_NUMBER = 'CARD_NUMBER',
  BANK_ACCOUNT_NUMBER = 'BANK_ACCOUNT_NUMBER',
  DOCUMENT_NUMBER = 'DOCUMENT_NUMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  BARCODE = 'BARCODE',
  FULL_NAME = 'FULL_NAME',
  MAC = 'MAC',
}
export enum EBlacklistStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export enum EConfirmationType {
  ADDRESS = 'ADDRESS',
  IDENTITY = 'IDENTITY',
  BUSINESS_IDENTITY = 'BUSINESS_IDENTITY',
}
export enum ECustomerVerificationRequestStatus {
  SUBMITTED = 'SUBMITTED',
  DELETED = 'DELETED',
  REJECTED = 'REJECTED',
  INCOMPLETE = 'INCOMPLETE',
  VERIFIED = 'VERIFIED',
  AWAITING_REPLY = 'AWAITING_REPLY',
}
export enum EUserRoles {
  ADMIN = 'ADMIN',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  CUSTOMER_READ = 'CUSTOMER_READ',
  DOCUMENT_ADMIN = 'DOCUMENT_ADMIN',
  DOCUMENT_READ = 'DOCUMENT_READ',
  SCREENING_READ = 'SCREENING_READ',
  TRANSACTION_READ = 'TRANSACTION_READ',
  EXPORT = 'Export'
}
